<template>
  <div id="register">
    <div class="text-center">
      <v-snackbar
          multi-line width="500px" centered shaped
          top v-model="snackbarStatus" :timeout="snackbarTimeout" :color="snackbarColor">
        <p v-html="snackbarText" style="margin-bottom: 0!important;"></p>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbarStatus = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <v-container fluid>
      <v-row no-gutters>
        <v-col sm="12" md="6" offset-md="3" offset-sm="0">
          <v-card color="#FAEDF0" class="pa-5 rounded-15" elevation="20">
            <v-row justify="center">
              <img
                  class="mt-3 mb-6"
                  width="50px"
                  height="50px"
                  src="https://upload.wikimedia.org/wikipedia/commons/a/ab/Android_O_Preview_Logo.png"
                  alt=""
              />
            </v-row>
            <v-form class="mt-3" @submit="register" v-model="form">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                      v-model="name"
                      label="نام"
                      align="end"
                      :rules="nameRules"
                      required
                      outlined
                      color="#292C6D"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="email"
                      label="ایمیل"
                      align="end"
                      :rules="emailRules"
                      outlined
                      color="#292C6D"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="phone"
                      label="موبایل"
                      align="end"
                      :rules="phoneRules"
                      outlined
                      color="#292C6D"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="referralCode"
                      label="کد معرف"
                      outlined
                      color="#292C6D"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="pass"
                      label="رمز عبور"
                      :rules="passwordRules"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      v-on:click:append="show1 = !show1"
                      outlined
                      color="#292C6D"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      v-model="confirmPass"
                      label="تکرار رمز عبور"
                      :rules="passwordConfirmationRule"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      v-on:click:append="show2 = !show2"
                      outlined
                      color="#292C6D"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-checkbox label="مرا بخاطر بسپار" color="#292C6D"></v-checkbox>
              <v-row>
                <v-col cols="6">
                  <v-btn class="mr-1 white--text" :disabled="!form" type="submit" color="#292C6D"
                  >ثبت نام
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <a class="mr-1 login_link mb-2" @click="navigateToLoginPage"
                  >قبلا عضو شده‌اید؟ وارد شوید
                  </a>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Register",
  data: () => {
    return {
      form: false,
      show1: false,
      show2: false,
      name: "",
      email: "",
      phone: "",
      pass: "",
      confirmPass: "",
      referralCode: "",
      nameRules: [(v) => !!v || "پر کردن این فیلد الزامی است"],
      emailRules: [
        (v) => !!v || "پر کردن این فیلد الزامی است",
        (v) => /.+@.+\..+/.test(v) || "ایمیل وارد شده صحیح نیست",
      ],
      phoneRules: [
        v => (v.length === 11 || v === '') || 'تعداد کاراکترها باید 11 باشد',
      ],
      passwordRules: [
        (v) => !!v || "پر کردن این فیلد الزامی است",
      ],
    };
  },
  computed: {
    passwordConfirmationRule() {
      if (!this.pass) {
        return ["پر کردن این فیلد الزامی است"]
      } else if (this.pass !== this.confirmPass) {
        return ['پسورد ها با هم یکسان نیستند']
      } else {
        return [true]
      }
    },
  },
  methods: {
    async register(e) {
      window.axios.post('user/register', {
        name: this.name,
        email: this.email,
        phone: this.phone,
        password: this.pass,
        referral_code: this.referralCode
      })
          .then(() => {
            this.$router.push('/login').then();
          }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        }
      })
      e.preventDefault();
    },
    navigateToLoginPage() {
      this.$router.push('/login').then();
    }
  },
};
</script>
<style scoped>
#register {
  background: linear-gradient(
      to bottom,
      rgb(41, 44, 109),
      rgba(41, 44, 109, 0.85)
  );
  height: 100vh;
  display: flex;
  align-items: center;
}

.theme--light.v-sheet--outlined {
  border-radius: 5px !important;
}
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 45px !important;
}

.v-text-field--outlined .v-label {
  top: 13px !important;
}

.v-icon.v-icon {
  font-size: 19px !important;
  top: -6px !important;
}

.v-input--selection-controls {
  margin-top: 0 !important;
}

.v-input--selection-controls .v-input__slot > .v-label {
  margin-top: -12px;
  font-size: 14px;
}
.login_link {
  font-size: 10px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: block;
  float: left;
  margin-top: 10px;
}
.mt-55{
  margin-top: 55px;
}
</style>